/* You can add global styles to this file, and also import other style files */
@use '../../../node_modules/@angular/material/index' as mat;
@import "../../shared/src/theme";
@import "../../../node_modules/@arcgis/core/assets/esri/themes/light/main.css";
//@import "https://js.arcgis.com/4.29/@arcgis/core/assets/esri/themes/light/main.css";
@import '../../../node_modules/@esri/calcite-components/dist/calcite/calcite.css';

html, body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
}


.density-3 {
  @include mat.all-component-densities(-3)
}

//
//app-widget-panel {
//  mat-card {
//    margin: 0 9px 0 10px;
//    height: 100%;
//    width: 100%;
//    display: flex;
//  }
//
//  mat-card-content {
//    flex: 1;
//  }
//
//  mat-card-actions {
//    display: flex;
//    justify-content: flex-end;
//  }
//
//  mat-card-actions button {
//    margin-left: 10px;
//  }
//}


app-widget-panel {
  mat-card {
    margin-left: 10px;
    height: 100%;
    width: 100%;
    display: flex;
  }

  mat-card-content {
    flex: 1;
  }

  mat-card-actions {
    display: flex;
    justify-content: flex-end;
  }

  mat-card-actions button {
    margin-left: 10px;
  }
}

.widget-panel-nav-div {
  .mat-mdc-tab-body-wrapper {
    flex: 1;
    display: flex;
  }

  .mat-mdc-tab-header {
    padding-bottom: 10px;
  }

  .mat-mdc-tab-body-content {
    display: flex;
    overflow-x: hidden;
  }
}


//// css trick to use checkboxes instead of eyes for visibility (use LayerList property visibilityAppearance after API 4.29)
//.esri-icon-non-visible::before {
//content: "\e610"!important;
//}
//.esri-icon-visible::before {
//content: "\e611"!important;
//}

#report {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 11in;
  width: 8.5in;
  margin-top: 0.2in;
  display: none;
}

.report-container {
  width: 11in;
  height: 8.5in;
  padding: 10px;
}

.report-header {
  border-bottom: 2px solid #000;
  margin-bottom: 0.2in;
}

.report-title {
  text-align: right;
  padding: 0.2in 0.5in 0.2in 0;
  margin: 0;
  font-weight: normal;
}

.report-body table {
  border-collapse: collapse;
  width: 100%;
}

.report-body td, th {
  border: 1px solid #000;
  padding: 8px;
}

.report-body th {
  text-align: center;
  background-color: #dddddd;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  .printing {
    display: flex !important;
    print-color-adjust: exact; // Otherwise background colors don't display when printing
  }

  .cdk-overlay-container {
    display: none !important;
  }
}

@page {
  margin-top: 0;
  margin-bottom: 0;
}

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border: none;
  margin-top: 10px;
}

.mat-button-toggle {
  border-radius: 50%;
  border-left: none !important;
  overflow: hidden;

  button {
    background-color: #e6f2fc;
    color: #000;
  }
}

.mat-button-toggle-checked {
  button {
    border-radius: 50%;
    background-color: #ffd454;
  }
}

.mdc-list-item__primary-text {
  display: grid;
  align-content: center;
  width: 100%;
  height: 48px;
}

.esri-widget__table tr th {
  border: 1px solid;
}

.esri-widget__table tr td {
  border: 1px solid;
}

.mdc-fab.autocomplete-close-button {
  height: 26px;
  width: 26px;
}

.autocomplete-toggle-dropdown {
  margin-right: 2px;
}

.autocomplete-toggle-dropdown.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  padding: 6px;
}

// Hide Legend widget layer caption - cannot be set to invisible programmatically
.esri-legend__layer-caption {
  display: none !important;
}

//// Hide layer list heading as well - redundant with layer toggle if you only have one sublayer legend item per sublayer. Currently commented out because it affects BLD layer list sublayer legends, which do have multiple legend items.
//.esri-widget__heading {
//  display: none;
//}

// // search form field backgrounds/borders
//.mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) {
//  background-color: white;
//  border: 1px solid #000;
//}


.hide-tabs {
  > .mat-mdc-tab-header {
    display: none !important;
    visibility: hidden;
  }
}

.widget-card {
  background-color: #e6f2fc !important;
  height: calc(100vh - 64px - 10px - 48px - 10px - 10px);
  // 100% viewport height - header - widget tabs & margin - bottom margin
  overflow-y: auto;
  //margin: 0 0 0 10px;
}


.file-drag:after, .loading:after, .list-loading tr:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

// to make the tab fully clickable and tooltip show up
app-widget-panel {

}




.file-drag:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

// to make the tab fully clickable and tooltip show up
.mdc-tab:has(.full-padding-tab) {
  padding-left: unset;
  padding-right: unset;
}

.mdc-tab__content:has(.full-padding-tab) {
  width: 100%;
}

.mdc-tab__text-label:has(.full-padding-tab) {
  width: 100%;
}


.print-standalone-checkbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
}

.print-standalone-checkbox {
  display: flex;
  padding-left: 16px;
}

.print-standalone-checkbox-label {
  align-self: center;
}

// to better align coordinate widget with expand widget
.esri-coordinate-conversion__conversions-view {
  margin: -2px 0;
}

label:has(#scaleLabel) {
  margin-left: -29px;
}

// does not work in component css
#eFilesBtn > span.mdc-button__label {
  z-index: 0 !important;
}
